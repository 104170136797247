import { AccountsOutlinedIcon, PartnerIcon, SubsIcon } from '@papertrail/styleguide'

import Menu from './Menu'
import React from 'react'

export default function MainNav({ id }: { id: string }) {
  const subscriptionId = id

  const subscriptionMenuItems = [
    { icon: AccountsOutlinedIcon, label: 'My Accounts', route: `/subscriptions/${subscriptionId}/accounts` },
    { icon: SubsIcon, label: 'My Subscription', route: `/subscriptions/${subscriptionId}/overview` },
    { icon: PartnerIcon, label: 'PartnerHub', route: `/partner-hub/${subscriptionId}` }
  ]

  if (subscriptionId) {
    return <Menu items={subscriptionMenuItems} />
  } else {
    return null
  }
}
