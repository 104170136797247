import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useApiGetFilters, useApiGetFolders, useApiGetReports, useApiGetIAuditor } from './hooks'
import { MenuItemData } from 'src/types'

import NavPanel from '../../components/NavPanel'
import MenuItem from '../../components/MenuItem'

import FiltersPanel from './FiltersPanel'
import FoldersPanel from './FoldersPanel'
import SettingsPanel from './SettingsPanel'

import './AccountNav.css'

import {
  IAuditorIcon,
  DashboardIcon,
  ActivitiesIcon,
  TasksIcon,
  FiltersIcon,
  AnalyticsIcon,
  FoldersIcon,
  SettingsIcon,
  Portal,
  Collapse
} from '@papertrail/styleguide'
import { EventManager, getDate } from '@papertrail/web3-utils'
import Menu from '../../components/Menu'
import { getFiltersBadge, getFolderById, recursivelyCalculateFolderState } from './utils'
import { useSessionAccount, useSessionMember, PapertrailSession } from '@papertrail/web3-session'

type Params = {
  id: string
}

export enum Panel {
  None,
  Folders,
  Filters,
  Settings
}

type Props = {
  popoutContainerRef: any
}

export default function AccountNav(props: Props) {
  const params = useParams<Params>()
  const accountId = params.id

  const account = useSessionAccount()
  const member = useSessionMember()

  // Quick fix to reload the member if not loaded yet
  if (!member) {
    PapertrailSession.reloadMember()
  }

  if (!account) {
    PapertrailSession.reloadAccount()
  }

  const endDate = getDate(0, 'months')
  const startDate = getDate(-2, 'months')

  const [reportsState, loadReports] = useApiGetReports(accountId)
  const [filtersState, loadFilters] = useApiGetFilters(accountId)
  const [iAuditorState, loadIAuditor] = useApiGetIAuditor(accountId)

  const rootFolderId = account ? account.rootFolderId : undefined
  const [folders, setFolders] = useState([])
  const [foldersState, loadFolders] = useApiGetFolders(accountId, rootFolderId, folders)

  const [panelOpen, setPanelOpen] = useState(Panel.None)

  const match = useRouteMatch('/accounts/:accountId/folders/:folderId')
  const currentFolderId = !!match && match.isExact ? (match.params as any).folderId : undefined

  const accountMenuItems: MenuItemData[] = [
    { icon: DashboardIcon, label: 'Dashboard', route: `/accounts/${accountId}`, onClick: closePanel },
    {
      icon: FiltersIcon,
      label: 'Filters',
      onClick: () => togglePanel(Panel.Filters),
      badge: getFiltersBadge(filtersState)
    },
    { icon: FoldersIcon, label: 'Folders', onClick: () => togglePanel(Panel.Folders) },
    {
      icon: ActivitiesIcon,
      label: 'Activities',
      route: `/accounts/${accountId}/activities?from=${startDate}&to=${endDate}`,
      onClick: closePanel
    }
  ]

  if (account && account.hasIAuditor && member && !member.isViewOnly) {
    accountMenuItems.push({
      icon: IAuditorIcon,
      label: 'Safety Culture',
      route: `/accounts/${accountId}/iauditor?used=false`,
      onClick: closePanel,
      badge: iAuditorState.isLoaded && iAuditorState.data ? 'error' : 'none'
    })
  }

  if (account && account.hasTasks) {
    accountMenuItems.push({
      icon: TasksIcon,
      label: 'Tasks',
      route: `/accounts/${accountId}/tasks?state=open,pending`, // todo: maybe consider passing in all non closed states in here?
      onClick: closePanel
    })
  }

  if (reportsState.isLoaded && reportsState.data.length > 0) {
    accountMenuItems.push({
      icon: AnalyticsIcon,
      label: 'Analytics',
      route: `/accounts/${accountId}/analytics`,
      onClick: closePanel
    })
  }

  useEffect(() => {
    loadReports({})
    loadIAuditor()
  }, [])

  useEffect(() => {
    const listenerRef = EventManager.subscribe(
      [
        'access_was_created',
        'access_was_deleted',
        'folder_was_created',
        'folder_was_updated',
        'folder_was_deleted',
        'folder_was_moved'
      ],
      () => {
        loadFolders({ include: `descendants`, format: 'tree' })
      }
    )
    return () => {
      EventManager.unsubscribe(listenerRef)
    }
  }, [])

  useEffect(() => {
    if (account) {
      loadFolders({ include: `descendants`, format: 'tree' })
    }
  }, [account])

  useEffect(() => {
    if (foldersState.isLoaded) {
      setFolders(foldersState.data)
    }
  }, [foldersState])

  useEffect(() => {
    loadFilters(currentFolderId)
  }, [currentFolderId])

  function togglePanel(panel: Panel) {
    if (panelOpen === panel) {
      setPanelOpen(Panel.None)
    } else {
      setPanelOpen(panel)
    }
  }

  function closePanel() {
    setPanelOpen(Panel.None)
  }

  const currentFolder = getFolderById(folders, currentFolderId)

  function toggleFolderExpansion(folderId: string) {
    const newFolderState = recursivelyCalculateFolderState(folders, folderId)
    setFolders(newFolderState)
  }

  //This config means the panel overlays the main content on small screens, and shrinks it on large screens
  const panelSx = {
    height: '100vh',
    zIndex: 10,
    position: {
      xs: 'fixed',
      md: 'relative'
    },
    left: {
      xs: '74px',
      md: 0
    }
  }

  return (
    <div className="account-nav-panel">
      <div>
        <Menu items={accountMenuItems} />

        <Portal container={props.popoutContainerRef.current}>
          <Collapse in={panelOpen !== Panel.None} orientation="horizontal" sx={panelSx}>
            {panelOpen === Panel.None && <div className="nav-panel"></div>}
            {panelOpen === Panel.Filters && (
              <NavPanel title="FILTERS" icon={FiltersIcon} onClose={() => setPanelOpen(Panel.None)}>
                <FiltersPanel
                  accountId={accountId}
                  folder={currentFolder}
                  rootFolderId={rootFolderId}
                  filtersState={filtersState}
                />
              </NavPanel>
            )}
            {panelOpen === Panel.Folders && (
              <NavPanel
                title="FOLDERS"
                icon={FoldersIcon}
                onClose={() => setPanelOpen(Panel.None)}
                headingLink={`/accounts/${accountId}/folders`}>
                <FoldersPanel accountId={accountId} folders={folders} toggleFolderExpansion={toggleFolderExpansion} />
              </NavPanel>
            )}
            {panelOpen === Panel.Settings && (
              <NavPanel title="SETTINGS" icon={SettingsIcon} onClose={() => setPanelOpen(Panel.None)}>
                <SettingsPanel onClose={() => setPanelOpen(Panel.None)} />
              </NavPanel>
            )}
          </Collapse>
        </Portal>
      </div>
      {member && member.isAdmin && (
        <MenuItem icon={SettingsIcon} label="Settings" onClick={() => togglePanel(Panel.Settings)} />
      )}
    </div>
  )
}
