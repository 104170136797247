import React from 'react'
import { MenuItemData } from '../types'
import { useHistory } from 'react-router-dom'
import MenuItem from './MenuItem'

type Props = {
  items: MenuItemData[]
}

export default function Menu(props: Props) {
  const history = useHistory()

  const onMenuClick = (menuItem: MenuItemData) => {
    if (menuItem.route) {
      history.push(menuItem.route)
    }
    if (menuItem.onClick) {
      menuItem.onClick()
    }
  }

  return (
    <>
      {props.items.map((menuItem) => {
        return (
          <MenuItem
            key={menuItem.label}
            icon={menuItem.icon}
            label={menuItem.label}
            badge={menuItem.badge}
            onClick={() => onMenuClick(menuItem)}
          />
        )
      })}
    </>
  )
}
