import MenuItem from './MenuItem'
import React from 'react'
import { navigateToUrl } from 'single-spa'

type Props = {
  items
}

export default function Menu(props: Props) {
  const onMenuClick = (menuItem) => {
    if (menuItem.route) {
      navigateToUrl(menuItem.route)
    }
    if (menuItem.onClick) {
      menuItem.onClick()
    }
  }

  return (
    <>
      {props.items.map((menuItem) => {
        return (
          <MenuItem
            key={menuItem.label}
            icon={menuItem.icon}
            color={menuItem?.color}
            label={menuItem.label}
            badge={menuItem.badge}
            onClick={() => onMenuClick(menuItem)}
          />
        )
      })}
    </>
  )
}
