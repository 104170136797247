/* eslint-disable import/no-extraneous-dependencies */

import { AccountsOutlinedIcon, ArrowBackNewIcon, PartnerIcon, SubsIcon } from '@papertrail/styleguide'
import { Box, Drawer, IconButton } from '@mui/material'

import MainNav from './MainNav'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'
import { navigateToUrl } from 'single-spa'
import { useParams } from 'react-router-dom'

type Params = {
  id: string
}

const Sidebar = () => {
  const params = useParams<Params>()
  const subscriptionId = params.id
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(open)
  }

  return (
    <Box paddingTop={2} width="72px" borderRight="1px solid  rgba(0, 0, 0, 0.12)">
      <Box
        display="flex"
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        borderBottom="1px solid  rgba(0, 0, 0, 0.12)">
        <Box
          paddingBottom={2}
          borderBottom={'1px solid  rgba(0, 0, 0, 0.12)'}
          width="100%"
          display="flex"
          flexDirection={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={2}>
          <div id="left-menu-logo-partner" onClick={() => navigateToUrl('/')}></div>
          <IconButton
            onClick={toggleDrawer(true)}
            sx={{
              '&:hover': {
                background: 'rgba(0, 122, 255, 0.08)'
              }
            }}>
            <MenuIcon sx={{ fontSize: '24px', color: '#007AFF' }} />
          </IconButton>
        </Box>

        <IconButton
          onClick={() => navigateToUrl(`/subscriptions/${subscriptionId}/accounts`)}
          sx={{
            borderRadius: '0px',
            height: '100%',
            width: '100%',
            padding: 2,
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.08)'
            }
          }}>
          <AccountsOutlinedIcon sx={{ fontSize: '24px', color: '#007AFF' }} />
        </IconButton>
        <IconButton
          onClick={() => navigateToUrl(`/subscriptions/${subscriptionId}/overview`)}
          sx={{
            borderRadius: '0px',
            height: '100%',
            width: '100%',
            padding: 2,
            '&:hover': {
              background: 'rgba(0, 0, 0, 0.08)'
            }
          }}>
          <SubsIcon sx={{ fontSize: '24px', color: '#007AFF' }} />
        </IconButton>
        <Box
          sx={{ background: 'rgba(0, 122, 255, 0.08)', height: '100%', width: '100%', marginBottom: '8px' }}
          display={'flex'}
          alignContent={'center'}
          alignItems={'center'}>
          <IconButton
            onClick={toggleDrawer(true)}
            sx={{
              flex: 1,
              padding: 2,
              borderRadius: '0px',
              '&:hover': {
                background: 'rgba(0, 122, 255, 0.08)'
              }
            }}>
            <PartnerIcon sx={{ fontSize: '24px', color: '#007AFF' }} />
          </IconButton>
        </Box>
      </Box>

      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <div role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <Box
            padding={2}
            borderBottom={'1px solid  rgba(0, 0, 0, 0.12)'}
            width="218px"
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'left'}
            gap={2}>
            <div id="left-menu-logo-partner"></div>

            <IconButton
              onClick={toggleDrawer(false)}
              sx={{
                width: '40px',
                background: 'rgba(0, 122, 255, 0.08)',
                '&:hover': {
                  background: 'rgba(0, 122, 255, 0.08)'
                }
              }}>
              <ArrowBackNewIcon sx={{ fontSize: '24px', color: '#007AFF', paddingLeft: '8px' }} />
            </IconButton>
          </Box>
          <Box borderBottom={'1px solid  rgba(0, 0, 0, 0.12)'}>
            <MainNav id={subscriptionId}></MainNav>
          </Box>
        </div>
      </Drawer>
    </Box>
  )
}
export default Sidebar
