import { ApiResponse, useApiGet } from '@papertrail/web3-utils'
import { FilterInfo, FolderInfo } from 'src/types'
import uniqBy from 'lodash/uniqBy'

export function useApiGetReports(accountId: string) {
  return useApiGet(`/accounts/${accountId}/analytic/reports`, (data: ApiResponse) => data.data)
}

export function useApiGetIAuditor(accountId: string) {
  const [iAuditorState, fetchIAuditor] = useApiGet<boolean>(
    `/accounts/${accountId}/iauditor`,
    (data: ApiResponse) => data.meta.pagination.total > 0
  )

  function loadIAuditor() {
    fetchIAuditor({ limit: 1, page: 1, sort: '-date_completed', used: false })
  }

  return [iAuditorState, loadIAuditor] as const
}

function mapFolders(data: ApiResponse, expandedfolderMap): FolderInfo[] {
  return data.data.map((folderData: ApiResponse) => ({
    id: folderData.id,
    name: folderData.name,
    children: folderData.children.data ? mapFolders(folderData.children, expandedfolderMap) : null,
    isExpanded: expandedfolderMap[folderData.id],
    partner: folderData.partner
  }))
}

function getExpandedFolderMap(folders: FolderInfo[]) {
  let map = {}
  if (folders && folders.length) {
    for (const folder of folders) {
      map[folder.id] = folder.isExpanded

      if (folder.children && folder.children.length) {
        map = { ...map, ...getExpandedFolderMap(folder.children) }
      }
    }
  }

  return map
}

export function useApiGetFolders(accountId: string, rootFolderId: string, existing: FolderInfo[]) {
  // I need a closure here
  const expandedFolderMap = getExpandedFolderMap(existing)

  function mapFoldersWithExisting(data: ApiResponse): FolderInfo[] {
    return data.data.map((folderData: ApiResponse) => ({
      id: folderData.id,
      name: folderData.name,
      children: folderData.children.data ? mapFolders(folderData.children, expandedFolderMap) : null,
      isExpanded: expandedFolderMap[folderData.id],
      partner: folderData.partner
    }))
  }
  return useApiGet(`/accounts/${accountId}/folders/${rootFolderId}`, mapFoldersWithExisting)
}

function mapFilters(data: ApiResponse): FilterInfo[] {
  return data.data.map((filterData: ApiResponse) => ({
    id: filterData.id,
    name: filterData.name,
    count: filterData.count,
    profile: filterData.profile,
    // We have to filter out duplicates because legacy MFE does not yet support duplicates in filters
    rule: uniqBy(filterData.rules.data, 'key')
      .map((r: any) => `${r.key}=${r.value}`)
      .join('&')
  }))
}

/** load filters with counts for specific folder if provided */
export function useApiGetFilters(accountId: string) {
  const [filtersState, fetchFilters] = useApiGet(`/accounts/${accountId}/filters`, mapFilters)
  function loadFilters(folderId: string) {
    fetchFilters({
      limit: 100,
      ...(folderId ? { folder_id: folderId } : {}),
      include: 'count,descendants,profile'
    })
  }
  return [filtersState, loadFilters] as const
}
