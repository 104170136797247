import React from 'react'
import { useHistory } from 'react-router-dom'
import { FilterInfo, FolderInfo } from 'src/types'

import { FetchState } from '@papertrail/web3-utils'
import { FoldersIcon } from '@papertrail/styleguide'

import './FiltersPanel.css'

type Props = {
  accountId: string
  folder: FolderInfo
  filtersState: FetchState<FilterInfo[]>
  rootFolderId: string
}

export default function FiltersPanel(props: Props) {
  const { accountId, folder, rootFolderId, filtersState } = props
  const history = useHistory()

  function onFilterClick(filter: FilterInfo) {
    const forFolderId = folder ? folder.id : rootFolderId

    history.push(
      `/accounts/${accountId}/folders/${forFolderId}?view=folder&recordsPage=1&recordsPerPage=50&${filter.rule}`
    )
  }

  function getFilterTextStyle(filter: FilterInfo) {
    switch (filter.profile) {
      case 'alert':
        return { color: 'red' }
      case 'warn':
        return { color: 'orange' }
      default:
        return { color: 'white' }
    }
  }

  const folderName = folder ? folder.name : 'All folders'

  if (filtersState.isLoading) {
    return <div className="filter-within-container">Please wait...</div>
  } else if (filtersState.isError) {
    return <div className="filter-within-container">Unable to load filters.</div>
  } else if (filtersState.isLoaded) {
    return (
      <div>
        <div className="filter-within-container">
          <span>Filter within:</span>
          <div className="filter-icon">
            <FoldersIcon fontSize="large" />
          </div>
          <b>{folderName}</b>
        </div>

        {filtersState.data.map((filter: FilterInfo) => {
          return (
            <div key={filter.id} className="filter" onClick={() => onFilterClick(filter)}>
              {filter.name}

              {filter.count > 0 && <b style={getFilterTextStyle(filter)}>{filter.count}</b>}

              {!(filter.count > 0) && <b />}
            </div>
          )
        })}
      </div>
    )
  } else {
    return null
  }
}
