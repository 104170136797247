import React from 'react'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { FolderInfo } from 'src/types'
import { FolderArrowDownIcon, FolderArrowRightIcon, SharedIndicator } from '@papertrail/styleguide'
import './FolderTreeItem.css'

type Params = {
  folderId: string
}

type Props = {
  folder: FolderInfo
  accountId: string
  depth: number
  toggleFolderExpansion: (folderId: string) => void
}

export default function FolderTreeItem(props: Props) {
  const { folder, accountId, depth, toggleFolderExpansion } = props
  const history = useHistory()
  const match = useRouteMatch('/accounts/:accountId/folders/:folderId')
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)

  let highlight = false

  if (match && match.isExact && !!folder && folder.id === (match.params as Params).folderId) {
    highlight = true
  }

  const itemClass = highlight ? 'tree-item-container-highlight' : 'tree-item-container'

  function onFolderClick() {
    if (!highlight) {
      const filteredOutKeys = ['accountId', 'folderId', 'recordsPage', 'recordsPerPage', 'view']
      const filterRules = []

      urlParams.forEach((value: string, key: string) => {
        if (!filteredOutKeys.includes(key)) {
          filterRules.push(`${key}=${value}`)
        }
      })

      let url = `/accounts/${accountId}/folders/${folder.id}?recordsPage=1&recordsPerPage=50`

      if (filterRules.length > 0) {
        url += `&${filterRules.join('&')}`
      }

      history.push(url)
    } else {
      toggleFolderExpansion(folder.id)
    }
  }

  function toggleClick(e) {
    e.stopPropagation()
    toggleFolderExpansion(folder.id)
  }

  function getSpacingStyle() {
    return {
      width: '20px',
      marginLeft: `${20 * (depth - 1)}px`
    }
  }

  function Arrow() {
    return (
      <div className="arrow-container" style={getSpacingStyle()} onClick={toggleClick}>
        {folder.children && (
          <>
            {!folder.isExpanded && <FolderArrowRightIcon />}
            {folder.isExpanded && <FolderArrowDownIcon />}
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <div className={itemClass} onClick={onFolderClick}>
        <Arrow />
        <div className="tree-item-title">{folder.name}</div>
        {!!folder.partner && <SharedIndicator variant="icon" />}
      </div>

      {folder.children !== null && folder.isExpanded && (
        <>
          {folder.children.map((folder: FolderInfo) => (
            <FolderTreeItem
              key={folder.id}
              folder={folder}
              accountId={accountId}
              depth={depth + 1}
              toggleFolderExpansion={toggleFolderExpansion}
            />
          ))}
        </>
      )}
    </>
  )
}
