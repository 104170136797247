import React from 'react'
import { ProfileIcon, PasswordIcon, InvitationsIcon, ProcessesIcon, EmailsIcon } from '@papertrail/styleguide'
import type { MenuItemData } from '../../types'
import Menu from '../../components/Menu'

function ProfileNav() {
  const profileMenuItems: MenuItemData[] = [
    { icon: ProfileIcon, label: 'Profile', route: '/profile/basic' },
    { icon: PasswordIcon, label: 'Password', route: '/profile/password' },
    { icon: InvitationsIcon, label: 'Invitations', route: '/profile/invitations' },
    { icon: ProcessesIcon, label: 'Processes', route: '/profile/processes' },
    { icon: EmailsIcon, label: 'Emails', route: '/profile/emails' }
  ]

  return <Menu items={profileMenuItems} />
}

export default ProfileNav
