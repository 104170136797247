import React from 'react'
import { ChildrenProps } from '@papertrail/styleguide/src/types'
import './NavPanel.css'
import { Box, CloseIcon, FlexRow } from '@papertrail/styleguide'
import { useHistory } from 'react-router-dom'

type Props = ChildrenProps & {
  onClose: (e) => void
  headingLink?: string
  title: string
  icon: any
}

export default function NavPanel(props: Props) {
  const { children, onClose, title, icon } = props
  const Icon = icon

  const history = useHistory()

  function headingLink() {
    if (props.headingLink) {
      history.push(props.headingLink)
    }
  }

  return (
    <div className="nav-panel">
      <FlexRow justifyContent="space-between" padding={2} alignItems="center" sx={{ width: '100%', cursor: 'pointer' }}>
        <Icon fontSize="large" />
        <Box flexGrow="1" px={0.5} onClick={headingLink}>
          {title}
        </Box>
        <CloseIcon fontSize="large" onClick={onClose} />
      </FlexRow>
      <Box height={'100%'}>{children}</Box>
    </div>
  )
}
