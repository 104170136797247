import React from 'react'
import { Badge } from '@papertrail/styleguide'
import './MenuItem.css'

type MuiIcon = any

type Props = {
  onClick: () => void
  icon: MuiIcon
  label: string
  badge?: 'error' | 'warning' | 'none'
}

export default function MenuItem(props: Props) {
  const iconStyle = { fontSize: '32px' }

  const { onClick, icon, label } = props
  const Icon = icon // capitalizing the prop makes it usable

  const hasBadge = props.badge === 'error' || props.badge === 'warning'
  const badgeColor = props.badge && props.badge !== 'none' ? props.badge : 'success'

  return (
    <div className="menu-item" onClick={onClick}>
      <Badge badgeContent={hasBadge ? 1 : 0} variant="dot" color={badgeColor}>
        <Icon style={iconStyle} />
      </Badge>
      <span className={'menuText'}>{label}</span>
    </div>
  )
}
