import React from 'react'
import { FolderInfo } from 'src/types'
import FolderTreeItem from './FolderTreeItem'

type Props = {
  accountId: string
  folders: FolderInfo[]
  toggleFolderExpansion: (folderId: string) => void
}

export default function FoldersPanel(props: Props) {
  const { accountId, folders, toggleFolderExpansion } = props
  return (
    <div className={'foldersPanel'}>
      {folders.map((folder: FolderInfo) => (
        <FolderTreeItem
          key={folder.id}
          accountId={accountId}
          folder={folder}
          depth={1}
          toggleFolderExpansion={toggleFolderExpansion}
        />
      ))}
    </div>
  )
}
