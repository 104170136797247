import { FilterInfo, FolderInfo } from '../../types'
import { FetchState } from '@papertrail/web3-utils'

export function recursivelyCalculateFolderState(folders: FolderInfo[], folderId: string) {
  return folders.map((folder: FolderInfo) => {
    if (folder.id === folderId) {
      return {
        ...folder,
        isExpanded: !folder.isExpanded,
        children: folder.children ? recursivelyCalculateFolderState(folder.children, folderId) : null
      }
    } else {
      return {
        ...folder,
        children: folder.children ? recursivelyCalculateFolderState(folder.children, folderId) : null
      }
    }
  })
}

export function getFolderById(foldersLocal: FolderInfo[], currentFolderId: string) {
  for (const folder of foldersLocal) {
    if (folder.id === currentFolderId) {
      return folder
    }

    const resultInChildren = folder.children ? getFolderById(folder.children, currentFolderId) : null

    if (resultInChildren) {
      return resultInChildren
    }
  }

  return null
}

export function getFiltersBadge(filtersState: FetchState<FilterInfo[]>): 'none' | 'warning' | 'error' {
  if (!filtersState.isLoaded) {
    return 'none'
  } else {
    return filtersState.data.reduce(
      (prevValue: 'none' | 'warning' | 'error', curValue: FilterInfo): 'none' | 'warning' | 'error' => {
        if (curValue.profile === 'alert' && curValue.count > 0) {
          return 'error'
        } else if (prevValue !== 'error' && curValue.profile === 'warn' && curValue.count > 0) {
          return 'warning'
        } else {
          return prevValue
        }
      },
      'none'
    )
  }
}
