/* eslint-disable import/no-extraneous-dependencies */
import { AccountsIcon, InvoicesIcon, OwnersIcon, PartnerIcon, SettingsIcon } from '@papertrail/styleguide'

import Menu from '../../components/Menu'
import { MenuItemData } from '../../types'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useApiGet } from '@papertrail/web3-utils'

type Params = {
  id: string
}

export default function SubscriptionNav() {
  const params = useParams<Params>()
  const subscriptionId = params.id
  const [subscriptionState, getSubscription] = useApiGet<any>(`/subscriptions/${subscriptionId}`, (data) => data)

  useEffect(() => {
    if (subscriptionId) getSubscription({})
  }, [subscriptionId])

  const subscriptionMenuItems: MenuItemData[] = [
    { icon: SettingsIcon, label: 'Settings', route: `/subscriptions/${subscriptionId}/overview` },
    { icon: AccountsIcon, label: 'Accounts', route: `/subscriptions/${subscriptionId}/accounts` },
    { icon: InvoicesIcon, label: 'Invoices', route: `/subscriptions/${subscriptionId}/invoices` },
    { icon: OwnersIcon, label: 'Owners', route: `/subscriptions/${subscriptionId}/owners` }
  ]

  if (subscriptionState.isLoaded && subscriptionState.data && subscriptionState.data.data) {
    const { supplier, consumer_partnership_invitation, consumer_partnerships } = subscriptionState.data.data
    if (supplier || consumer_partnership_invitation > 0 || consumer_partnerships > 0) {
      subscriptionMenuItems.push({
        icon: PartnerIcon,
        label: 'PartnerHub',
        route: `/partner-hub/${subscriptionId}`,
        color: 'white'
      })
    }
  }

  if (subscriptionId && subscriptionState.isLoaded) {
    return <Menu items={subscriptionMenuItems} />
  } else {
    return null
  }
}
