import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import './SettingsItem.css'

type Props = {
  route: string
  onClose: () => void
  label: string
  icon: any // () => ReactElement doesn't work
}

type Params = {
  accountId: string
}

const styles = {
  icon: {
    fontSize: 20
  }
}

export default function SettingsItem(props: Props) {
  const { route, label, onClose, icon } = props
  const history = useHistory()
  const accountRouteMatch = useRouteMatch('/accounts/:accountId')
  const exactRouteMatch = useRouteMatch('/accounts/:accountId' + route)
  const accountId = (accountRouteMatch.params as Params).accountId
  const settingsItemClass = exactRouteMatch && exactRouteMatch.isExact ? 'settings-item-selected' : 'settings-item'
  const Icon = icon

  function navigate() {
    history.push(`/accounts/${accountId}${route}`)
    onClose()
  }

  return (
    <div className={settingsItemClass} onClick={navigate}>
      <div className="icon-container">
        <Icon sx={styles.icon} />
      </div>
      {label}
    </div>
  )
}
